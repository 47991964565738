import cn from "classnames"
// import { components, OptionProps, SingleValueProps } from "react-select"
// import AsyncSelect from "react-select/async"
import SelectSearch from "react-select-search"
import { useSettings } from "src/layout/page/settingsContext"
import countryCodes, { popularCountryCodes } from "./countrycodes"
import s from "./SendSms.module.scss"
import { Locale } from "src/sanity/types"
import { useEffect } from "react"

export type CountryCodeOption = {
    value: string
    name: string
    phoneCode: string
    countryName: string
    countryNameEn: string
    emoji: string
}

function countryCodesToOptions(lang) {
    const countryCodeOptions: CountryCodeOption[] = countryCodes.map(c => ({
        value: c.phoneCode, name: c.phoneCode, phoneCode: c.phoneCode, countryName: c.name[lang], countryNameEn: c.name["en"], emoji: c.emoji,
    }))
    return countryCodeOptions
}

export function getDefaultCountryCode(lang: Locale | "uk") {
    const countryCodeOptions = countryCodesToOptions(lang)
    const langToCountryCode = {
        dk: "Denmark",
        en: "Denmark",
        se: "Sweden",
        no: "Norway",
        uk: "United Kingdom",
    }
    const defaultCountryCode = countryCodeOptions.find(c => c.countryNameEn === langToCountryCode[lang])
    return defaultCountryCode
}
export function getCountryCodeOptionsFromCode(code: string, lang: Locale) {
    const countryCodeOptions = countryCodesToOptions(lang)
    const countryCode = countryCodeOptions.find(c => c.phoneCode === code)
    return countryCode
}

export default function CountryCodeSelect({ phoneInputRef, countryCode, setCountryCode, required = false }) {
    const { lang = "dk" } = useSettings()
    const countryCodeOptions = countryCodesToOptions(lang)
    useEffect(() => {
        if (!countryCode) {
            const defaultCountryCode = getDefaultCountryCode(lang)
            setCountryCode(defaultCountryCode.phoneCode)
        }
    }, [])

    const popularCountryCodeOptions = countryCodeOptions.filter(c => popularCountryCodes.indexOf(c.countryNameEn) > -1)
    popularCountryCodeOptions.sort((a, b) => popularCountryCodes.indexOf(a.countryNameEn) - popularCountryCodes.indexOf(b.countryNameEn))

    return (
        <SelectSearch
            id="phone-country-code"
            value={countryCode}
            search
            fuzzySearch={false}
            getOptions={async (searchValue) => countryCodeOptions.filter((c: CountryCodeOption) => (c.countryName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) || (c.phoneCode.indexOf(searchValue) > -1))} // When searching, show all
            debounce={0}
            options={popularCountryCodeOptions}

            onChange={(val, newCountryCode) => { setCountryCode((newCountryCode as unknown as CountryCodeOption).value); phoneInputRef?.current?.focus() }}
            renderOption={(props, option, snapshot, className) => {
                return (
                    <button {...props as any} className={className}>
                        <span>{option.emoji}</span>
                        <span style={{ margin: "0px 20px", minWidth: "60px", display: "inline-block" }}>{option.phoneCode}</span>
                        <span>{option.countryName}</span>
                    </button>
                )
            }}
            renderValue={(valueProps, snapshot, className) => (
                <input {...valueProps as any} tabIndex={-1} required={required} onKeyPress={(e) => { if (e.key === "Enter") { e.preventDefault(); return } if ((snapshot.highlighted as unknown as number) < 0) { valueProps.onKeyDown(new KeyboardEvent("ArrowDown", { key: "ArrowDown" })) } }} className={cn(className, s["send-sms__input__pre-fix"])} />
            )}

            onBlur={() => void 0}
            onFocus={() => void 0}
        />
    )
}
